img {
    display: inline;
}

img.icon {
    max-width: 100%;
    height: auto;
}

.small-icons-block {
    display: flex;
    /* flex-wrap: wrap; */
    flex-flow: row wrap;
    justify-content:space-between;
}

.small-icons-block::after {
    content: "";
    width: 50px;
}

ul.icons-block {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    grid-gap: 1rem;
    justify-content: space-between;
    
    /* other properties */
    list-style: none;
    background: #fefaf3; /* #fdf8ef; */
    padding: 1rem;
    margin: 0 auto;
}

.small-icon-container {
    display: inline-flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border: 1.2px solid black;
    /* border-style: solid;
    border-width: 1.2px;
    border-color: black; */
}

img.small-icon {
    max-width: 100%;
    max-height: auto;
}

td.icon-cell {
    height: 100px;
    text-align: center;
    vertical-align: middle;
}

.footer-col-wrapper-extra {
    color: #595959;
}

a {
    text-underline-offset: 0.35rem;
}

.twi-orthography {
    color: darkred;
}

figure > img {
    display: inline;
}

figure.article-body {
    text-align: center;
}

.figure-div {
    float: right;
    margin: 0 0 15px 20px;
}

.float-fig {
    display: table;
}

.table-fig {
    display: inline-table;
    align-items: center;
}

.figcaption {
    display: table-caption;
    caption-side: bottom;
    padding: 10px;
}

.figs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}